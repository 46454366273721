import React, {useState, useEffect} from "react";

export default function ClassicoJackeBack({
  lightContrast,
  darkContrast,
  stitching,
  patchColor,
  azomo,
  printStyle,
  getDataFromChild,
  preview,
}) {
  function adjust(color, amount) {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
          ).substr(-2)
        )
    );
  }

  const [darkenColor, setDarkenColor] = useState(lightContrast);
  const [darkenColorContrast, setDarkenColorContrast] = useState(darkContrast);

  const [lightContrastActive, setLightContrastActive] = useState(false);
  const [darkContrastActive, setDarkContrastActive] = useState(false);
  const [stitchingActive, setStitchingActive] = useState(false);
  const [patchActive, setPatchActive] = useState(false);
  const [azomoActive, setAzomoActive] = useState(false);

  const handleClick = (tabId) => {
    if (!preview) {
      getDataFromChild(tabId);
    }
  };

  useEffect(() => {
    setDarkenColor(adjust(lightContrast, -50));
    setDarkenColorContrast(adjust(darkContrast, -100));
  }, [lightContrast, darkContrast]);

  return (
    <svg
      className={printStyle}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g>
        <path
          fill={lightContrast}
          onClick={() => handleClick("base")}
          onMouseEnter={() => setLightContrastActive(true)}
          onMouseLeave={() => setLightContrastActive(false)}
          style={
            lightContrastActive && !preview
              ? {fill: adjust(lightContrast, 55)}
              : {fill: lightContrast}
          }
          d="M458.764,446.854c-1.3-19.93-3.1-43.51-6.57-67.22c-1.36-9.3-2.26-18.78-3.14-27.94
		c-0.46-4.83-0.93-9.83-1.47-14.75c-0.42-3.84-1.2-7.71-1.96-11.44c-0.45-2.211-0.91-4.5-1.29-6.74
		c-1.65-9.74-3.41-19.71-5.229-29.62c-4.551-24.7-11.071-47.99-17.961-72.651c-0.88-3.129-1.76-6.259-2.63-9.409
		c-7.37-26.47-15.58-50.56-23.77-69.671c-2.82-6.579-5.639-12.87-7.9-17.929c-2.64-5.88-4.91-10.96-4.93-11.54h-0.23l0.1-0.1
		c-0.89-0.91-0.96-0.96-1.29-1.131c-0.15-0.079-0.38-0.199-0.889-0.55c-21.211-14.439-65.171-28.7-75-31.79
		c0.509-0.949-0.201-2.88-1.141-5.109c-0.18-0.45-0.35-0.84-0.45-1.13l-7.67-20.87c-1.37-3.301-4.25-5.71-7.7-6.43
		c-4.42-0.93-9.82-1.131-14.59-1.31c-1.37-0.05-2.71-0.1-3.98-0.17c-5.76-0.3-11.88-0.401-18.71-0.34
		c-7.93,0.08-18.81,0.189-27.35,1.439c-0.88,0.131-1.75,0.271-2.62,0.411l-0.77,0.12c-1.9,0.3-3.61,1.309-4.81,2.83l-10.959,25.02
		l-0.241,0.56c-0.54,1.25-0.95,2.25-0.96,2.72c-0.01,0.53,0.19,1,0.54,1.37c-48.589,14.639-70.75,25.85-80.79,32.679
		c-0.51,0.351-0.74,0.471-0.89,0.55c-0.33,0.171-0.409,0.221-1.29,1.131l0.1,0.1h-0.23c0,1.24-1.18,3.07-2.13,4.54
		c-0.38,0.57-0.73,1.12-0.97,1.559c-2.43,4.551-4.79,9.011-7.04,13.531c-5.03,10.169-8.82,20.78-12.56,31.66
		c-7.68,22.259-14.22,45.19-19.44,68.139c-1.79,7.84-3.8,15.801-5.74,23.491c-2.01,8-4.1,16.28-5.95,24.46
		c-5.159,22.85-9.29,46.879-12.61,73.469c-0.44,3.5-0.85,7.011-1.26,10.521c-0.789,6.67-1.6,13.57-2.59,20.32
		c-2.199,15.09-3.73,30.12-4.89,44.2l-0.05,0.19c0.01,0,0.02,0.01,0.04,0.01c-0.048,0.581-0.09,1.148-0.137,1.725l-0.125,0.417
		l0.089,0.026c-0.57,7.12-1.051,14-1.478,20.481c-0.14,0.109-0.229,0.279-0.24,0.479c-0.03,0.321,0.15,0.601,0.43,0.701
		c20.04,6.92,33.72,8.78,42.65,8.78c4.59,0,7.931-0.49,10.23-1.04c0.031-0.02,0.81-0.401,1.15-1.41
		c0.08-0.481,8.07-48.14,10.95-56.19c2.44-6.801,18.73-66.95,20.83-78.5c1.34-7.381,5.68-22.94,9.89-37.99
		c0.62-2.24,1.24-4.46,1.85-6.65c3.67,44.73,6.96,89.62,6.42,101.29c-1.15,25.009-1.32,67.93-1.33,68.37
		c0.01,0.269,0.72,6.71,49.07,19.849c13.83,2.801,28.38,4.281,44.48,4.531c3.95,0.059,7.8,0.1,11.58,0.1
		c20.07,0,37.79-1.011,55.66-4.631c48.26-13.13,50.84-19.489,50.93-19.759l0.01-0.1c0-0.43-0.17-43.351-1.32-68.36
		c-0.53-11.43,2.22-54.741,5.37-98.55l0.92,3.309c4.39,15.781,8.53,30.691,9.97,38.591c2.101,11.55,18.39,71.699,20.82,78.5
		c2.89,8.05,10.88,55.709,10.97,56.239c0.32,0.96,1.1,1.341,1.2,1.38c2.27,0.54,5.58,1.031,10.14,1.031
		c8.91,0,22.61-1.861,42.68-8.79l0.22-0.08L458.764,446.854z"
        />
        <path
          fill="none"
          stroke="#010101"
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
		M100.808,432.965c-2.886,0.532-6.287,0.802-10.121,0.802c-12.32,0-27.781-2.719-45.981-8.084"
        />
        <path
          fill="none"
          stroke="#010101"
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M402.12,432.71
		c2.897,0.467,5.376,0.642,9.21,0.555c12.317-0.278,26.698-3.023,44.771-8.797"
        />
        <path
          fill="none"
          stroke="#010101"
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M204.754,70.686
		c9.536-1.559,54.393-7.993,97.982,0.783"
        />
        <path
          fill="none"
          stroke="#010101"
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="M356.84,442.452
		c-2.095,2.294-12.827,8.369-50.051,18.494c-22.254,4.501-44.524,4.869-67.154,4.521c-15.03-0.232-29.661-1.536-44.419-4.521
		c-37.238-10.129-46.063-16.205-48.153-18.497"
        />
        <path
          fill="none"
          stroke="#010101"
          strokeWidth="0.45"
          strokeMiterlimit="10"
          strokeDasharray="1.343,1.342"
          d="
		M356.838,424.353c-2.099,2.297-12.837,8.371-50.049,18.493c-22.254,4.5-44.524,4.868-67.154,4.52
		c-15.03-0.232-29.661-1.535-44.419-4.52c-35.787-9.734-45.332-15.725-47.877-18.213"
        />
        <path
          fill="#010101"
          d="M458.764,446.577c-1.3-19.93-3.1-43.51-6.57-67.22c-1.36-9.3-2.26-18.78-3.14-27.94
		c-0.46-4.83-0.93-9.83-1.47-14.75c-0.42-3.84-1.2-7.71-1.96-11.44c-0.45-2.211-0.91-4.5-1.29-6.74
		c-1.65-9.74-3.41-19.71-5.229-29.62c-4.551-24.7-11.071-47.99-17.961-72.651c-0.88-3.129-1.76-6.259-2.63-9.409
		c-7.37-26.47-15.58-50.56-23.77-69.671c-2.82-6.579-5.639-12.87-7.9-17.929c-2.64-5.88-4.91-10.96-4.93-11.54h-0.23l0.1-0.1
		c-0.89-0.91-0.96-0.96-1.29-1.131c-0.15-0.079-0.38-0.199-0.889-0.55c-21.211-14.439-65.171-28.7-75-31.79
		c0.509-0.949-0.201-2.88-1.141-5.109c-0.18-0.45-0.35-0.84-0.45-1.13l-7.67-20.87c-1.37-3.301-4.25-5.71-7.7-6.43
		c-4.42-0.93-9.82-1.131-14.59-1.31c-1.37-0.05-2.71-0.1-3.98-0.17c-5.76-0.3-11.88-0.401-18.71-0.34
		c-7.93,0.08-18.81,0.189-27.35,1.439c-0.88,0.131-1.75,0.271-2.62,0.411l-0.77,0.12c-1.9,0.3-3.61,1.309-4.81,2.83l-10.959,25.02
		l-0.241,0.56c-0.54,1.25-0.95,2.25-0.96,2.72c-0.01,0.53,0.19,1,0.54,1.37c-48.589,14.639-70.75,25.85-80.79,32.679
		c-0.51,0.351-0.74,0.471-0.89,0.55c-0.33,0.171-0.409,0.221-1.29,1.131l0.1,0.1h-0.23c0,1.24-1.18,3.07-2.13,4.54
		c-0.38,0.57-0.73,1.12-0.97,1.559c-2.43,4.551-4.79,9.011-7.04,13.531c-5.03,10.169-8.82,20.78-12.56,31.66
		c-7.68,22.259-14.22,45.19-19.44,68.139c-1.79,7.84-3.8,15.801-5.74,23.491c-2.01,8-4.1,16.28-5.95,24.46
		c-5.159,22.85-9.29,46.879-12.61,73.469c-0.44,3.5-0.85,7.011-1.26,10.521c-0.789,6.67-1.6,13.57-2.59,20.32
		c-2.199,15.09-3.73,30.12-4.89,44.2l-0.05,0.19c0.01,0,0.02,0.01,0.04,0.01c-0.65,7.9-1.18,15.509-1.65,22.65
		c-0.14,0.109-0.229,0.279-0.24,0.479c-0.03,0.321,0.15,0.601,0.43,0.701c20.04,6.92,33.72,8.78,42.65,8.78
		c4.59,0,7.931-0.49,10.23-1.04c0.031-0.02,0.81-0.401,1.15-1.41c0.08-0.481,8.07-48.14,10.95-56.19
		c2.44-6.801,18.73-66.95,20.83-78.5c1.34-7.381,5.68-22.94,9.89-37.99c0.62-2.24,1.24-4.46,1.85-6.65
		c3.67,44.73,6.96,89.62,6.42,101.29c-1.15,25.009-1.32,67.93-1.33,68.37c0.01,0.269,0.72,6.71,49.07,19.849
		c13.83,2.801,28.38,4.281,44.48,4.531c3.95,0.059,7.8,0.1,11.58,0.1c20.07,0,37.79-1.011,55.66-4.631
		c48.26-13.13,50.84-19.489,50.93-19.759l0.01-0.1c0-0.43-0.17-43.351-1.32-68.36c-0.53-11.43,2.22-54.741,5.37-98.55l0.92,3.309
		c4.39,15.781,8.53,30.691,9.97,38.591c2.101,11.55,18.39,71.699,20.82,78.5c2.89,8.05,10.88,55.709,10.97,56.239
		c0.32,0.96,1.1,1.341,1.2,1.38c2.27,0.54,5.58,1.031,10.14,1.031c8.91,0,22.61-1.861,42.68-8.79l0.22-0.08L458.764,446.577z
		 M204.214,69.377l11.141-25.47c1.099-1.38,2.649-2.28,4.369-2.561l0.77-0.119c0.87-0.14,1.74-0.27,2.611-0.4
		c8.5-1.25,19.349-1.361,27.269-1.441c6.81-0.069,12.92,0.04,18.67,0.341c1.27,0.06,2.61,0.119,3.99,0.17
		c4.741,0.18,10.11,0.38,14.48,1.3c3.24,0.67,5.94,2.93,7.22,6.03l7.67,20.85c0.1,0.3,0.27,0.7,0.46,1.16
		c0.58,1.39,1.67,3.99,1.14,4.64c-0.11,0.139-0.35,0.17-0.7,0.1c-16.87-3.4-33.99-4.511-49.16-4.511
		c-24.289,0-43.6,2.851-48.94,3.731c-0.56,0.09-1.099-0.04-1.46-0.351c-0.29-0.25-0.45-0.609-0.44-0.989
		C203.314,71.466,203.874,70.157,204.214,69.377 M96.844,453.766c-0.23,0.66-0.739,0.941-0.739,0.95
		c-6.631,1.581-22.261,2.681-52.441-7.739c-0.01-0.011-0.01-0.06,0-0.08c0,0.01-0.02,0.02-0.03,0.02l-0.06-0.321l0.32,0.021
		c0.47-7.14,1-14.76,1.64-22.681c17.77,5.2,32.96,7.83,45.15,7.83c3.85,0,7.33-0.259,10.13-0.739
		C98.644,443.016,96.874,453.567,96.844,453.766 M138.514,281.057c-4.21,15.07-8.56,30.65-9.9,38.05
		c-2.1,11.54-18.37,71.609-20.8,78.4c-1.52,4.25-4.44,19.35-6.88,32.839c-2.76,0.5-6.39,0.771-10.25,0.771
		c-12.159,0-27.329-2.64-45.09-7.84c1.15-13.97,2.67-28.87,4.86-43.82c0.99-6.771,1.8-13.67,2.58-20.34
		c0.41-3.51,0.821-7.021,1.26-10.521c3.33-26.569,7.45-50.58,12.6-73.41c1.85-8.17,3.94-16.439,5.95-24.439
		c1.95-7.7,3.95-15.66,5.74-23.51c5.22-22.93,11.76-45.83,19.42-68.07c3.74-10.86,7.521-21.451,12.53-31.58
		c2.241-4.52,4.61-8.97,7.04-13.51c0.22-0.42,0.57-0.95,0.93-1.52c0.74-1.14,1.61-2.49,2.01-3.69c1.15,5.95,6.64,34.79,11.7,65.08
		c0.93,10.12,4.95,54.12,8.62,98.769C140.084,275.447,139.294,278.247,138.514,281.057 M355.844,375.907
		c1.13,24.519,1.31,66.269,1.32,68.26c-0.32,0.62-4.62,6.83-50.44,19.299c-21.15,4.281-42.11,4.901-67.09,4.511
		c-16.05-0.25-30.56-1.72-44.33-4.511c-47.11-12.809-48.529-19-48.57-19.229c0.01-0.43,0.18-43.33,1.33-68.33
		c1.16-25.03-15.24-202.4-15.4-204.18l-0.16,0.01c-5.38-31.971-11.11-61.78-11.54-64c0.58-0.59,0.641-0.62,0.85-0.73
		c0.16-0.081,0.41-0.21,0.961-0.581c10.029-6.83,32.239-18.059,81.099-32.75h0.01c0.43,0.17,0.92,0.241,1.42,0.161
		c8.67-1.42,54.1-8.03,97.87,0.78c0.16,0.03,0.31,0.05,0.44,0.05c0.15,0,0.27-0.04,0.39-0.071c8.96,2.801,53.81,17.241,75.23,31.83
		c0.541,0.371,0.791,0.5,0.96,0.581c0.21,0.11,0.27,0.14,0.85,0.73c-0.43,2.22-6.17,32.029-11.54,64l-0.16-0.01
		c-0.01,0.1-0.06,0.75-0.15,1.88c-0.02,0.079-0.03,0.159-0.05,0.239l0.03,0.011C367.525,194.117,354.754,352.367,355.844,375.907
		 M394.194,397.507c-2.44-6.791-18.7-66.86-20.8-78.4c-1.44-7.931-5.59-22.85-9.98-38.641l-1.41-5.059
		c3.32-45.96,7.04-91.941,7.83-101.731c5.11-30.569,10.65-59.599,11.7-65.04c0.571,1.571,2.08,4.971,4.71,10.841
		c2.27,5.05,5.08,11.34,7.9,17.92c8.18,19.069,16.39,43.14,23.74,69.58c0.88,3.15,1.75,6.289,2.63,9.42
		c6.89,24.64,13.41,47.92,17.95,72.59c1.82,9.91,3.58,19.87,5.23,29.6c0.38,2.259,0.85,4.549,1.29,6.77
		c0.76,3.72,1.541,7.569,1.95,11.38c0.54,4.92,1.02,9.91,1.48,14.74c0.87,9.17,1.78,18.65,3.14,27.98
		c2.11,14.4,3.6,28.75,4.73,42.27c-17.69,5.619-32.83,8.619-45.009,8.89c-3.92,0.09-7.451-0.101-10.25-0.54
		C398.584,416.647,395.694,401.727,394.194,397.507 M405.964,454.737c-0.03-0.01-0.57-0.28-0.79-0.92
		c-0.04-0.23-1.83-10.95-4.03-23.07c2.35,0.349,5.16,0.54,8.28,0.54c0.61,0,1.23,0,1.86-0.021c12.21-0.269,27.37-3.259,45.06-8.869
		c0.7,8.47,1.27,16.61,1.77,24.21l0.01,0.44C428.084,457.386,412.514,456.297,405.964,454.737"
        />
      </g>
    </svg>
  );
}
